import { SystemLanguage } from '@zeiss/ng-vis-common/types';
var enGB = {
  gbp: {
    routes: {
      title: 'GBP',
      desc: 'Manage global business partners',
      organization: {
        desc: 'Manage organizations data',
        title: 'Organizations'
      },
      individual: {
        desc: 'Manage individuals data',
        title: 'Individuals'
      },
      manualBroadcast: {
        desc: 'Manual broadcast',
        title: 'Manual Broadcast'
      },
      metadata: {
        desc: 'Manage metadata',
        title: 'Metadata'
      }
    }
  }
};
var esES = {
  gbp: {}
};
var deDE = {
  gbp: {
    routes: {
      title: 'GBP',
      desc: 'Kudenstammdaten verwalten',
      organization: {
        desc: 'Organisationsdaten verwalten',
        title: 'Organisationen'
      },
      individual: {
        desc: 'Personendaten verwalten',
        title: 'Personen'
      },
      manualBroadcast: {
        desc: 'Manueller Broadcast',
        title: 'Manueller Broadcast'
      },
      metadata: {
        desc: 'Metadaten verwalten',
        title: 'Metadaten'
      }
    }
  }
};
const GBP_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: {
    ...enGB
  },
  [SystemLanguage['es-ES']]: {
    ...esES
  },
  [SystemLanguage['de-DE']]: {
    ...deDE
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { GBP_TRANSLATIONS };
